body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ViewOurServices:hover {
  background: 'rgb(8, 253, 253)';
}
.submitnow:hover {
  color: blue;
  background: rgb(8, 253, 253);
}

.viexwabcd {
  background-color: rgba(36, 51, 92, 0.7);
  background: rgba(36, 51, 92, 0.7);
}

/* from desktop */

@media screen and (max-width: 500px) {
  .callUs {
    display: none;
  }
}

@media screen and (max-width: 1100px) {
  .search {
    display: none;
  }
}

@media screen and (max-width: 1120px) {
  .email {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .containerPic {
    display: none;
  }
}

#navbarNav {
  float: right;
}

.navbar-nav {
  margin-left: 20%;
}

@media screen and (max-width: 800px) {
  .navbar-nav {
    margin-left: 0px;
  }
}

.separate {
  margin-left: 5px;
  margin-right: 5px;
}

.nav-link {
  font-weight: bold;
}

/* for car*/

.loop-wrapper {
  margin: 0 auto;
  position: relative;
  display: block;
  width: 80%;
  height: 250px;
  overflow: hidden;
  border-bottom: 3px solid #fff;
  color: #fff;
}
.mountain {
  position: absolute;
  right: -900px;
  bottom: -20px;
  width: 2px;
  height: 2px;
  box-shadow: 0 0 0 50px #4db6ac, 60px 50px 0 70px #4db6ac,
    90px 90px 0 50px #4db6ac, 250px 250px 0 50px #4db6ac,
    290px 320px 0 50px #4db6ac, 320px 400px 0 50px #4db6ac;
  transform: rotate(130deg);
  animation: mtn 20s linear infinite;
}
.hill {
  position: absolute;
  right: -900px;
  bottom: -50px;
  width: 400px;
  border-radius: 50%;
  height: 20px;
  box-shadow: 0 0 0 50px #4db6ac, -20px 0 0 20px #4db6ac, -90px 0 0 50px #4db6ac,
    250px 0 0 50px #4db6ac, 290px 0 0 50px #4db6ac, 620px 0 0 50px #4db6ac;
  animation: hill 4s 2s linear infinite;
}
.tree,
.tree:nth-child(2),
.tree:nth-child(3) {
  position: absolute;
  height: 100px;
  width: 35px;
  bottom: 0;
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/130015/tree.svg)
    no-repeat;
}
.rock {
  height: 2%;
  width: 2%;
  bottom: -2px;
  border-radius: 20px;
  position: absolute;
  background: #ddd;
}
.truck,
.wheels {
  transition: all ease;
  width: 85px;
  margin-right: -60px;
  bottom: 0px;
  right: 50%;
  position: absolute;
  background: #eee;
}
.truck {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/130015/truck.svg)
    no-repeat;
  background-size: contain;
  height: 60px;
}
.truck:before {
  content: ' ';
  position: absolute;
  width: 25px;
  box-shadow: -30px 28px 0 1.5px #fff, -35px 18px 0 1.5px #fff;
}
.wheels {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/130015/wheels.svg)
    no-repeat;
  height: 15px;
  margin-bottom: 0;
}

.tree {
  animation: tree 3s 0s linear infinite;
}
.tree:nth-child(2) {
  animation: tree2 2s 0.15s linear infinite;
}
.tree:nth-child(3) {
  animation: tree3 8s 0.05s linear infinite;
}
.rock {
  animation: rock 4s -0.53s linear infinite;
}
.truck {
  animation: truck 4s 0.08s ease infinite;
}
.wheels {
  animation: truck 4s 0.001s ease infinite;
}
.truck:before {
  animation: wind 1.5s 0s ease infinite;
}

@keyframes tree {
  0% {
    transform: translate(1350px);
  }
  50% {
  }
  100% {
    transform: translate(-50px);
  }
}
@keyframes tree2 {
  0% {
    transform: translate(650px);
  }
  50% {
  }
  100% {
    transform: translate(-50px);
  }
}
@keyframes tree3 {
  0% {
    transform: translate(2750px);
  }
  50% {
  }
  100% {
    transform: translate(-50px);
  }
}

@keyframes rock {
  0% {
    right: -200px;
  }
  100% {
    right: 2000px;
  }
}
@keyframes truck {
  0% {
  }
  6% {
    transform: translateY(0px);
  }
  7% {
    transform: translateY(-6px);
  }
  9% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(-1px);
  }
  11% {
    transform: translateY(0px);
  }
  100% {
  }
}
@keyframes wind {
  0% {
  }
  50% {
    transform: translateY(3px);
  }
  100% {
  }
}
@keyframes mtn {
  100% {
    transform: translateX(-2000px) rotate(130deg);
  }
}
@keyframes hill {
  100% {
    transform: translateX(-2000px);
  }
}
.leaflet-container {
  height: 700px;
  width: 80%;
  margin: 0 auto;
}
.modal-content {
  border-radius: 10px !important;
  border: 10px !important;
}
.pinkBg {
  background-color: #ff0000 !important;
  background-image: linear-gradient(90deg, #ff0000, #ff0000);
}

.intro-banner-vdo-play-btn .ripple {
  position: absolute;
  width: 160px;
  height: 160px;
  z-index: 1;
  left: 45%;
  top: 52%;
  opacity: 0;
  margin: -80px 0 0 -80px;
  border-radius: 100px;
  -webkit-animation: ripple 1.8s infinite;
  animation: ripple 1.8s infinite;
}

@-webkit-keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.intro-banner-vdo-play-btn .ripple:nth-child(2) {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}
.intro-banner-vdo-play-btn .ripple:nth-child(3) {
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}
